<template>
  <section class="answersPoll">
    <div class="answersPoll__filters">
      <div class="answersPoll__searchButtonContainer">
        <button @click="showFiltersAction" class="answersPoll__searchButton">
          <iconic name="building" class="answersPoll__filtersSearchIcon" />
          Ciudad
        </button>
        <Filters ref="filtersRef" v-show="showFilters" @filters="applyFilters" />
      </div>
      <label for="" class="answersPoll__search" ref="label">
        <iconic name="search" class="answersPoll__filtersSearchIcon" />
        <input type="text" placeholder="Buscar usuarios" class="answersPoll__filtersSearchInput" />
      </label>

      <div v-if="filters.length" class="answersPoll__filtersApply">
        <span v-for="filter in filters" :key="filter" class="answersPoll__filtersApplyItem">
          {{ filter }}
          <iconic @click.native="dropFiltro(filter)" name="close" class="answersPoll__filtersApplyIcon" />
        </span>
      </div>
    </div>

    <div class="answersPoll__totalUsers">
      <LinealGraph :title="'Cantidad de personas que pueden votar'" :percentage="percentage" />
    </div>

    <div v-for="question in stadistics" :key="question._id" class="answersPoll__graphLinear">
      <LinealGraphs :question="question" :key="graph" />
      <div class="answersPoll__graphLinearOptions">
        <Options v-for="option in question.responsesWithData" :key="option._id" :question="option" />
      </div>
    </div>
  </section>
</template>
<script>
  import { mapGetters } from "vuex";
  import LinealGraphs from "./LinealGraphs.vue";
  import Options from "./Options.vue";
  import LinealGraph from "../../widgets/Graphs/LinealGraph.vue";
  export default {
    name: "answers",
    components: {
      LinealGraphs,
      LinealGraph,
      Options,
      Filters: () => import("@/components/widgets/Filters.vue"),
    },
    data() {
      return {
        stadistics: [],
        stadisticsPrev: [],
        chartOptions: {
          colors: ["#bd0909"],
        },
        showFilters: false,
        graph: 0,
        filters: [],
      };
    },
    computed: {
      ...mapGetters("polls", ["getPoll"]),
      dataGraphs() {
        return this.getPoll.totals;
      },
      percentage() {
        if (!this.getPoll?.pollInProgress) return 0;

        const votersLength = this.getPoll?.pollInProgress.voters.length ?? 0;
        const noVotersLength = this.getPoll?.pollInProgress.noVoters.length ?? 0;
        const totalVoters = votersLength + noVotersLength;
        if (totalVoters === 0) return 0;
        return (votersLength / (noVotersLength + votersLength)) * 100;
      },
    },
    methods: {
      showFiltersAction(e) {
        e.preventDefault();
        this.showFilters = !this.showFilters;
      },

      applyFilters(filters) {
        this.filters = filters;

        if (!this.filters.length) {
          this.stadistics = this.stadisticsPrev;
          return;
        }
        const newData = this.stadisticsPrev.map((response) => {
          const responsesWithDataNew = response.responsesWithData.map((votes) => {
            const filteredVotes = votes.votes.filter((user) => filters.includes(user.office));

            return {
              ...votes,
              votes: filteredVotes,
            };
          });

          const newEle = {
            ...response,
            responsesWithData: responsesWithDataNew,
          };

          return newEle;
        });

        this.stadistics = newData;
        this.showFilters = false;
        this.graph++;
      },

      dropFiltro(filtro) {
        this.filters = this.filters.filter((fil) => fil !== filtro);
        this.applyFilters(this.filters);
        if (!this.filters.length) {
          this.stadistics = this.stadisticsPrev;
          return;
        }
        this.$refs.filtersRef.deleteFilter(filtro);
      },
    },
    watch: {
      getPoll: {
        immediate: true,
        handler(newPoll) {
          if (newPoll?.totals) {
            this.stadistics = newPoll.totals;
            this.stadisticsPrev = newPoll.totals;
          }
        },
      },
    },
  };
</script>
<style lang="scss">
  .answersPoll {
    @include Flex(column);
    min-width: 100%;
    max-width: 100%;
    &__filters {
      width: 100%;
      max-width: 1440px;
      min-height: 80px;
      border-radius: 6px;
      margin: 10px auto;
      background-color: #fff;
      padding: 20px;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
    }
    &__search,
    &__searchButton {
      @include Flex(row, flex-start);
      width: 100%;
      margin-bottom: 10px;
      padding: 5px 10px;
      border-radius: 20px;
      border: 1px solid rgba(229, 229, 229, 1);
    }
    &__searchButtonContainer {
      position: relative;
      @include Flex(row, flex-start);
      width: 100%;
      border-radius: 20px;
      border: 1px solid rgba(229, 229, 229, 1);
    }
    &__filtersSearchIcon {
      margin-right: 5px;
      font-size: 17px;
      color: black;
    }
    &__filtersSearchInput {
      border: none;
      ::placeholder {
        color: rgba(157, 157, 157, 1);
      }
    }
    &__searchButton {
      background: transparent;
    }

    &__graphLinear {
      min-height: 120px;
      width: 100%;
      max-width: 1440px;
      padding: 20px;
      margin-top: 20px;
      border-radius: 6px;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
    }

    &__filtersApply {
      @include Flex(row, flex-start);
      margin-top: 0.5rem;
    }

    &__filtersApplyItem {
      display: flex;
      width: fit-content;
      margin-right: 1rem;
      padding: 0.2rem 0.6rem;
      color: $primary;
      font-weight: bold;
      border-radius: 1rem;
      background-color: rgba(242, 189, 189, 0.3);
    }
    &__filtersApplyIcon {
      font-size: 0.6rem;
      margin-left: 0.3rem;
      cursor: pointer;
    }

    &__totalUsers {
      width: 100%;
      max-width: 1440px;
      border-radius: 6px;
      margin: 10px auto;
      background-color: #fff;
      padding: 20px;
      border: 1px solid #00000040;
      box-shadow: 0px 1px 4px 0px #00000040;
    }

    &__totalTitle {
      font-weight: bold;
    }

    &__percentageBar {
      width: 100%;
      height: 11px;
      background-color: #f0f0f0;
      border-radius: 7px;
      transition: 1s ease-in-out;
      &--fill {
        width: 0;
        max-width: 100%;
        height: 100%;
        background-color: $primary-color;
        border-radius: 7px;
      }
    }
    &__platformHeader {
      @include Flex(row, space-between);
      width: 100%;
      gap: 5px;
      font-family: $sec-font;
    }
    &__platformData {
      @include Flex(row);
      gap: 10px;
      font-size: 16px;
    }
    &__percentage {
      font-size: 19px;
      color: $primary-color;
    }

    @media (min-width: 769px) {
      &__filters {
        @include Flex();
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      &__search,
      &__searchButton {
        margin-bottom: 0;
      }
      &__search {
        margin-left: 15px;
      }
      &__graphLinear {
        display: grid;
        grid-template-columns: 40% 1fr;
      }
    }
  }
</style>
