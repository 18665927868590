<template>
  <div class="answersPoll__graphsLineal">
    <p class="answersPoll__questionName">{{ question.question }}</p>
    <div>
      <bar-chart v-if="total.length > 10" :max="100" suffix="%" :data="total" :library="chartOptions" height="100%"></bar-chart>
      <column-chart v-else class="" :min="0" :max="100" suffix="%" :data="total" :library="chartOptions" height="100%"></column-chart>
    </div>
  </div>
</template>
<script>
export default {
  props: ["question"],
  data() {
    return {
      total: [],
      isTest: [],
      chartOptions: {
        colors: ["#bd0909"],
      },
      users: [],
    };
  },
  methods: {
    truncateName(text) {
      if (text.length > 10) {
        return text.substr(0, 10) + "...";
      } else {
        return text;
      }
    },
  },
  mounted() {
    this.total = this.question.responsesWithData.map((ele) => [this.truncateName(ele.text), ele.votes.length]);
  },
};
</script>
<style lang="scss">
.answersPoll {
  &__graphsLineal {
    * {
      font-family: $sec_font;
      font-weight: 400;
    }
  }
  &__questionName {
    margin-bottom: 10px;
    font-family: $sec_font;
    line-height: 19.2px;
    font-weight: bold;
  }
}
</style>
