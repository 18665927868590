<template>
  <section class="globalLinealGraph">
    <p class="globalLinealGraph__title">{{ title }}</p>
    <div class="globalLinealGraph__wrapper">
      <div class="globalLinealGraph__platformHeader">
        <span>Total</span>
        <span class="globalLinealGraph__percentage">{{ `${percentage}%` }}</span>
      </div>
      <ProgressBar :widthBar="percentage" />
    </div>
  </section>
</template>
<script>
export default {
  components: {
    ProgressBar: () => import("./ProgressBar.vue"),
  },
  props: ["title", "percentage"],
  data() {
    return {
      chartOptions: {
        colors: ["#bd0909"],
      },
    };
  },
};
</script>
<style lang="scss">
.globalLinealGraph {
  @include Flex(column);
  min-width: 100%;
  max-width: 100%;

  &__title {
    font-weight: bold;
    text-transform: capitalize;
  }

  &__wrapper {
    width: 100%;
  }
  &__platformHeader {
    @include Flex(row, space-between);
    width: 100%;
    gap: 5px;
    font-family: $sec-font;
  }
  &__percentage {
    font-size: 19px;
    color: $primary-color;
  }
}
</style>
