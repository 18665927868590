<template>
  <div class="answersPoll__optionContainer">
    <p @click="changeShowUsers" class="answersPoll__option">
      {{ question.text }}
      <i :class="'percent____contentDropdown fa ' + (!showUsers ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up')"></i>
    </p>
    <div v-if="showUsers" class="answersPoll__optionUsers custom__scroll">
      <OptionUserInformation v-for="user in question.votes" :key="user._id" :user="user" />
    </div>
  </div>
</template>
<script>
import OptionUserInformation from "./OptionUserInformation.vue";
export default {
  props: ["question"],
  components: {
    OptionUserInformation,
  },
  data() {
    return {
      showUsers: false,
    };
  },
  computed: {},
  methods: {
    changeShowUsers() {
      this.showUsers = !this.showUsers;
    },
    truncateName({ artisticName, user }) {
      const totalLength = (artisticName || user).length;

      if (totalLength > 10) {
        return (artisticName || user).substr(0, 10) + "...";
      } else {
        return artisticName || user;
      }
    },
  },
};
</script>
<style lang="scss">
.answersPoll {
  &__optionContainer {
    padding: 5px;
    margin-bottom: 10px;
  }
  &__option {
    @include Flex(row, space-between);
    width: 100%;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 6px;
    color: $primary;
    font-weight: bold;
    font-family: $sec_font;
    background: rgba(242, 242, 242, 1);
    cursor: pointer;
  }
  &__optionUsers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-height: 100px;
    min-height: 80px;
    padding: 0 10px;
  }

  @media (min-width: 769px) {
    &__optionContainer {
      padding: 0px;
    }
  }
  @media (min-width: 1024px) {
    &__optionUsers {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.custom__scroll {
  overflow-y: auto;
}
.custom__scroll::-webkit-scrollbar {
  width: 17px;
}

.custom__scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom__scroll::-webkit-scrollbar-thumb {
  background-color: rgb(151, 151, 151);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.custom__scroll::-webkit-scrollbar-thumb:hover {
  background-color: #4d4d4d;
}
</style>
