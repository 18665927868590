<template>
  <div class="answersPoll__optionAvatarContainer">
    <!-- <div :stateAvatar="state"> -->
    <Avatar class="answersPoll__optionAvatar" :user="user" />
    <!-- <span class="userSelected__statusCircle" :stateAvatar="state"></span> -->
    <!-- </div> -->
    <span @click="moreInformation" class="answersPoll__optionName">
      {{ truncateName(user) }}
    </span>
    <div v-if="moreInformationS" class="answersPoll__optionUser">
      <div class="answersPoll__optionUserContainer">
        <iconic @click.native="moreInformation" class="answersPoll__optionClose" name="close" />
        <div>
          <iconic class="answersPoll__optionIcon" name="date" />
          <span>20/11/2023 - 01:55PM</span>
        </div>
        <div>
          <iconic class="answersPoll__optionIcon" name="skip" />
          <span>Omitio 0 veces</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["user"],
  data() {
    return {
      users: [],
      showUsers: false,
      moreInformationS: false,
    };
  },
  computed: {
    // state() {
    //   const { isActive, isOnline } = this.user;
    //   return isActive && isOnline ? "online" : !isActive && isOnline ? "away" : "offline";
    // },
  },
  methods: {
    moreInformation() {
      this.moreInformationS = !this.moreInformationS;
    },
    truncateName({ artisticName, user }) {
      const totalLength = (artisticName || user).length;

      if (totalLength > 10) {
        return (artisticName || user).substr(0, 10) + "...";
      } else {
        return artisticName || user;
      }
    },
  },
};
</script>
<style lang="scss">
.answersPoll {
  &__optionAvatarContainer {
    position: relative;
    @include Flex(row, flex-start);
    margin-right: 5px;
    margin-bottom: 8px;
    align-self: flex-start;
    cursor: pointer;
  }
  &__optionUser {
    * {
      font-size: 14px;
    }
    position: absolute;
    padding: 15px;
    border-radius: 6px;
    top: 0px;
    left: 30px;
    z-index: 11;
    background: #fff;
    box-shadow: 0 1px 7px rgba(87, 87, 87, 0.25);
  }
  &__optionName {
    font-size: 14px;
    text-transform: capitalize;
  }
  &__optionAvatar {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__optionUserContainer {
    position: relative;
  }
  &__optionClose {
    position: absolute;
    top: -11px;
    right: -12px;
    color: $primary;
    font-weight: bold;
  }
  &__optionIcon {
    margin-right: 5px;
  }
}
</style>
